<template>
  <div
    v-if="this.$route.matched.length > 2"
    class="animated fadeIn"
  >
    <router-view />
  </div>
  <div
    v-else
    class="animated fadeIn"
  >
    <ReportsFilter
      :category="currentCategoryParam"
      @filter="getFilterData"
    />
    <b-card no-body>
      <b-tabs
        v-model="categories.category"
        card
      >
        <b-tab
          id="category-without-manager"
          name="category"
          title="Отчет без разбивки по менеджерам"
          lazy
        >
          <auction-report-without-manager :filter="filter" />
        </b-tab>
        <b-tab
          id="category-with-manager"
          name="category"
          title="Отчет с разбивкой по менеджерам"
          lazy
        >
          <auction-report-with-manager :filter="filter" />
        </b-tab>
        <b-tab
          id="category-completed"
          name="category"
          title="Отчет подробный по рейсам"
          lazy
        >
          <auction-report-completed :filter="filter" />
        </b-tab>
        <b-tab
          id="category-contractors"
          name="category"
          title="Участие перевозчиков"
          lazy
        >
          <auction-category :filter="filter" />
        </b-tab>
        <b-tab
          id="category-money"
          name="category"
          title="Деньги"
          lazy
        >
          <money-report :filter="filter" />
        </b-tab>
        <b-tab
          id="category-number-distribuition"
          name="category"
          title="Распределение рейсов по кол-ву"
          lazy
        >
          <route-number-distribution-report :filter="filter" />
        </b-tab>

      </b-tabs>
    </b-card>
  </div>
</template>

<script>
import Vue from 'vue';
import {ClientTable, Event} from 'vue-tables-2';
import ReportsFilter from './ReportsFilter/ReportsFilter';
import {
  routeOwnersList,
  customersContractorsList,
} from '../../services/api';
import {queryToUrl, URLToQuery} from '@/services/http';
import AuctionReportWithoutManager from './ReportBlocks/AuctionReportWithoutManager/AuctionReportWithoutManager';
import MoneyReport from './ReportBlocks/MoneyReport/MoneyReport';
import MoneyByRoutesReport from './ReportBlocks/MoneyByRoutesReport/MoneyByRoutesReport';
import AuctionReportWithManager from './ReportBlocks/AuctionReportWithManager/AuctionReportWithManager';
import AuctionReportCompleted from './ReportBlocks/AuctionReportCompleted/AuctionReportCompleted';
import RouteNumberDistributionReport from './ReportBlocks/RouteNumberDistributionReport/RouteNumberDistributionReport';
import AuctionCategory from './ReportBlocks/AuctionCategory/AuctionCategory';

Vue.use(ClientTable);

export default {
  name: 'ReportsAuctions',
  components: {
    RouteNumberDistributionReport,
    MoneyByRoutesReport,
    MoneyReport,
    AuctionReportWithoutManager,
    AuctionReportWithManager,
    AuctionReportCompleted,
    AuctionCategory,
    ReportsFilter,
  },
  data() {
    return {
      lastRefreshTime: null,
      managers: [],
      contractors: [],
      categories: {
        category: 0,
        withOutManagers: {
          value: 0,
          param: 'without-managers',
          text: 'Отчет без разбивки по менеджерам',
        },
        withManagers: {
          value: 1,
          param: 'with-managers',
          text: 'Отчет с разбивкой по менеджерам',
        },
        completed: {
          value: 2,
          param: 'completed',
          text: 'Отчет подробный по рейсам',
        },
        contractors: {
          value: 3,
          param: 'contractors',
          text: 'Участие перевозчиков',
        },
        money: {
          value: 4,
          param: 'money',
          text: 'Деньги',
        },
        routeNumberDistribution: {
          value: 5,
          param: 'route-number-dist',
          text: 'Распределение рейсов по количеству',
        },
      },
      filter: {},
      pickerOptions: {
        firstDayOfWeek: 1,
      },
      loading: false,
    };
  },
  computed: {
    currentCategoryParam() {
      let param = '';
      switch (this.categories.category) {
      case 0:
        param = this.categories.withOutManagers.param;
        break;
      case 1:
        param = this.categories.withManagers.param;
        break;
      case 2:
        param = this.categories.completed.param;
        break;
      case 3:
        param = this.categories.contractors.param;
        break;
      case 4:
        param = this.categories.money.param;
        break;
      case 5:
        param = this.categories.routeNumberDistribution.param;
        break;
      default:
        break;
      }
      return param;
    },
  },
  updated() {
    this.updateRouteParams();
  },
  mounted() {
    this.customersRoutesManagersList();
    const queries = this.$router.currentRoute.query;
    /**
     * Если в URL есть параметр 'limit'
     * то перекидываем таблицу на соответствующее
     * параметру значение
     * */
    if (queries.limit) {
      this.$refs.table.setLimit(queries.limit);
    }
    /**
     * Если в URL есть параметр 'page'
     * то перекидываем таблицу на соответствующее
     * параметру значение
     * */
    if (queries.page) {
      this.$refs.table.setPage(queries.page);
    }
  },
  created() {
    this.initRouteParams();
  },
  methods: {
    async customersRoutesManagersList(query) {
      this.loading = true;
      const params = {limit: 100};
      if (query) {
        params.query = query;
      }
      const response = await routeOwnersList( params);
      if (response && response.status === 200) {
        this.managers = response.data.items.map((item) => {
          return {
            value: item.id,
            text: item.name,
          };
        });
      }
      if (this.filter.manager) {
        await this.customersRoutesSelectedManagersList();
      }
      this.loading = false;
    },
    async customersRoutesSelectedManagersList() {
      this.loading = true;
      const response = await routeOwnersList({
        'id[0]': this.filter.manager,
      });
      if (response && response.status === 200) {
        response.data.items.forEach((item) => {
          this.managers = this.managers.filter((x) => x.value !== item.id);
          this.managers.unshift({
            value: item.id,
            text: item.name,
          });
        });
      }
      this.loading = false;
    },
    async customersContractorsList(name) {
      this.loading = true;
      const params = {limit: 100};
      if (name) {
        params.name = name;
      }
      const response = await customersContractorsList(this.$store.state.user.ownerId, params);
      if (response && response.status === 200) {
        this.contractors = response.data.items.map((item) => {
          return {
            value: item.contractor.id,
            text: item.contractor.name,
          };
        });
      }
      if (this.filter.contractor) {
        this.customersSelectedContractorsList();
      }
      this.loading = false;
    },
    async customersSelectedContractorsList() {
      this.loading = true;
      const response = await customersContractorsList(this.$store.state.user.ownerId, {
        'id[0]': this.filter.contractor,
      });
      if (response && response.status === 200) {
        response.data.items.forEach((item) => {
          this.contractors = this.contractors.filter((x) => x.value !== item.contractor.id);
          this.contractors.unshift({
            value: item.contractor.id,
            text: item.contractor.name,
          });
        });
      }
      this.loading = false;
    },
    getFilterData(data) {
      this.filter = data;
    },
    updateRouteParams() {
      const res = {};
      if (this.categories.category) {
        switch (this.categories.category) {
        case 0:
          res.category = this.categories.withOutManagers.param;
          break;
        case 1:
          res.category = this.categories.withManagers.param;
          break;
        case 2:
          res.category = this.categories.completed.param;
          break;
        case 3:
          res.category = this.categories.contractors.param;
          break;
        case 4:
          res.category = this.categories.money.param;
          break;
        case 5:
          res.category = this.categories.routeNumberDistribution.param;
          break;
        default:
          break;
        }
      }
      // if (this.filter.manager) {
      //   res.manager = this.filter.manager;
      // }
      // if (this.filter.contractor) {
      //   res.contractor = this.filter.contractor;
      // }
      // if (this.filter.date_from) {
      //   res.date_from = moment(this.filter.date_from).format('YYYY-MM-DD');
      // }
      // if (this.filter.date_to) {
      //   res.date_to = moment(this.filter.date_to).format('YYYY-MM-DD');
      // }
      this.$store.commit('setFilterParams', {componentName: this.$options.name, params: {...this.filter}});
      if (this.filter.page && this.filter.page !== 1) {
        res.page = this.filter.page;
      } else {
        delete res.page;
      }
      history.pushState({}, document.title, this.$router.currentRoute.path);
      if (Object.keys(res).length !== 0) {
        const url = queryToUrl('', res);
        history.pushState(null, null, url);
      }
      return res;
    },
    /**
     * Запись параметров из URL в this.filter
     * */
    initRouteParams() {
      const params = URLToQuery(this.$route.fullPath);
      if (Object.keys(params).length !== 0) {
        if (params.hasOwnProperty('category')) {
          switch (params.category) {
          case 'without-managers':
            this.categories.category = 0;
            break;
          case 'with-managers':
            this.categories.category = 1;
            break;
          case 'completed':
            this.categories.category = 2;
            break;
          case 'contractors':
            this.categories.category = 3;
            break;
          case 'money':
            this.categories.category = 4;
            break;
          case 'route-number-dist':
            this.categories.category = 5;
            break;
          case 'route-money-dist':
            this.categories.category = 7;
            break;
          default:
            break;
          }
        }
        if (params.hasOwnProperty('manager')) {
          params.manager = Number(params.manager);
        }
        if (params.hasOwnProperty('contractor')) {
          params.contractor = Number(params.contractor);
        }
        if (params.hasOwnProperty('page')) {
          params.page = Number(params.page);
        }
        this.filter.ready = false;
        this.$nextTick(()=> {
          Object.assign(this.filter, params);
          this.filter.ready = true;
        });
      } else {
        const params = this.$store.getters.getFilterParams(this.$options.name);
        this.filter.ready = false;
        if (params) {
          this.$nextTick(()=> {
            Object.assign(this.filter, params);
            this.filter.ready = true;
          });
        } else {
          this.filter.ready = true;
        }
      }
    },
  },

};
</script>

<style lang="scss">

</style>
